import Flickity from 'flickity';
import FlickityFade from 'flickity-fade';
require('flickity-imagesloaded');
require('flickity-sync');

let sliders = document.querySelectorAll('.slider');
if (sliders !== null) {
    for (var i = 0; i < sliders.length; i++) {
        const slider = sliders[i];

        // Get autoplay option via data-autoplay, whether integer,boolean or not set
        //
        let autoPlay = false
        if (slider.getAttribute('data-autoplay') == 'true') {
            autoPlay = true;
        } else if (slider.getAttribute('data-autoplay') !== '') {
            autoPlay = parseInt(slider.getAttribute('data-autoplay'));
        }

        let sliderInstance = new Flickity(slider, {
            cellSelector: '.slide',
            adaptiveHeight: true,
            autoPlay: autoPlay,
            pageDots: true,
            prevNextButtons: false,
            pauseAutoPlayOnHover: true,
            wrapAround: true,
            imagesLoaded: true,
            fade: true
        });

        let sliderNext = slider.querySelector('.slider--next');
        let sliderPrev = slider.querySelector('.slider--prev');

        if (sliderNext !== null) {
            sliderNext.addEventListener("click", function (e) {
                e.preventDefault();
                slider.next();
            });
        }

        if (sliderPrev !== null) {
            sliderPrev.addEventListener("click", function (e) {
                e.preventDefault();
                slider.previous();
            });
        }
    }
}

let awardSliders = document.querySelectorAll('.slider-awards');
let awardDateSlider = document.querySelector('.slider-awards-dates');
if (awardSliders !== null) {
    for (var i = 0; i < awardSliders.length; i++) {
        const awardSlider = awardSliders[i];
        const awardSlides = awardSlider.querySelectorAll('.slide-award');

        // Get first slide index based on data-first-slide-index if set, else set to first slide
        const firstSlideIndex = awardSlider.getAttribute('data-first-slide-index') ? awardSlider.getAttribute('data-first-slide-index') - 1 : 0;

        let sliderInstance = new Flickity(awardSlider, {
            cellSelector: '.slide-award',
            sync: '.slider-awards-dates',
            pageDots: false,
            prevNextButtons: false,
            wrapAround: false,
            imagesLoaded: true,
            draggable: true,
            cellAlign: 'center',
            percentPosition: false,
            // Begin slider at the last slide
            //
            initialIndex: firstSlideIndex
        });

        let sliderDateInstance = new Flickity(awardDateSlider, {
            cellSelector: '.slide-date',
            pageDots: false,
            sync: '.slider-awards',
            prevNextButtons: true,
            wrapAround: false,
            imagesLoaded: true,
            draggable: false,
            cellAlign: 'center',
            percentPosition: false,
            arrowShape: {
                x0: 25,
                x1: 65, y1: 45,
                x2: 70, y2: 40,
                x3: 35
            },
            // Begin slider at the last slide
            //
            initialIndex: firstSlideIndex
        });
    }
}




