const scrollToLinks = document.querySelectorAll('[data-scrollto]')

if ( scrollToLinks !== null ) {
    for (let i = 0; i < scrollToLinks.length; i++) {
        const scrollToLink = scrollToLinks[i]

        scrollToLink.addEventListener('click', function (e) {
            e.preventDefault()

            // Scroll to section ID set within data-scrollto and account for header height
            //
            const headerHeight = document.querySelector('#header').clientHeight

            if (document.querySelector(scrollToLink.getAttribute('data-scrollto')) !== null) {
                const scrollToPosition = document.querySelector(scrollToLink.getAttribute('data-scrollto')).getBoundingClientRect().top + window.pageYOffset  - headerHeight;

                window.scroll({
                    top: scrollToPosition,
                    behavior: 'smooth'
                })
            }
        })
    }
}