import Vue from 'vue';

Vue.config.productionTip = false;
import {postToController} from './../Utils';

if (document.querySelector('#form')) {
    new Vue({
        el: '#form',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            firstName: null,
            lastName: null,
            fromEmail: null,
            telephone: null,
            reason: '',
            toEmail: null,
            message: null
        },
        methods: {
            changeReason: function (e) {
                this.toEmail = document.querySelector('#reason').options[document.querySelector('#reason').options.selectedIndex].dataset.reason
            },
            send: function (e) {
                if (!this.firstName) {
                    this.errors.push("Name required.");
                }
                if (!this.fromEmail) {
                    this.errors.push('Email required.');
                }
                if (!this.message) {
                    this.errors.push('Please enter a message.');
                }

                if (!this.errors.length) {
                    let bodyFormData = new FormData();
                    bodyFormData.append('action', 'contact-form/send');
                    bodyFormData.append('subject', this.subject);
                    bodyFormData.append('toEmail', this.toEmail);
                    bodyFormData.append('fromName', this.firstName + " " + this.lastName);
                    bodyFormData.append('fromEmail', this.fromEmail);
                    bodyFormData.append('message[Telephone]', this.telephone);
                    bodyFormData.append('message[Reason]', this.reason);
                    bodyFormData.append('message[Message]', this.message);
                    bodyFormData.append('message[Token]', this.generateToken());

                    postToController(
                        '', bodyFormData,
                        (response) => {
                            // Handle success
                            console.log('success')
                            document.querySelector('.form-hold').classList.add("form-hold--complete");

                            // Scroll to success message
                            this.handleScrollToSuccess();
                        },
                        (response) => {
                            // Handle error
                            console.log('error')
                            alert('An error occurred. Please try again.');
                        }
                    );
                }
                e.preventDefault();
            },
            handleSelectInitialChange: function (e) {
                // Ensures that when option is selected, colour is white to match other fields.
                e.target.classList.add('is-selected');
            },
            handleScrollToSuccess: function () {
                const formComplete = document.querySelector('.form-complete');
                const scrollToPosition = formComplete.getBoundingClientRect().top + window.pageYOffset;

                window.scroll({
                    top: scrollToPosition,
                    behavior: 'smooth'
                })
            },
            generateToken: function () {
                return btoa(this.fromEmail + this.reason);
            }
        },
        created() {
            this.toEmail = document.querySelector('[data-reason]').getAttribute('data-reason');
        }
    });
}
