//###########################################################################
// Tabs
//###########################################################################

const tabs = {
    config: {
        tabs: '.tabs',
        tab: '.tab',
        tabsNav: '.tabs-nav',
        tabsNavItem: '.tabs-nav__item'
    },

    elements: {
        tabs: null,
        tabsItems: null,
        tabsNavItems: null,
    },

    init() {
        const self = this

        self.elements.tabs = document.querySelector(self.config.tabs)

        if (self.elements.tabs !== null) {
            self.elements.tabsItems = self.elements.tabs.querySelectorAll(self.config.tab)
            self.elements.tabsNav = document.querySelector(self.config.tabsNav)
            self.elements.tabsNavItems = self.elements.tabsNav.querySelectorAll(self.config.tabsNavItem)

            self.handleTabs()
        }
    },

    handleTabs() {
        const self = this

        for (let i = 0; i < self.elements.tabsNavItems.length; i++) {
            const tabNavItem = self.elements.tabsNavItems[i]
            self.handleTabNavItem(tabNavItem)
        }
    },

    handleTabNavItem(tabNavItem) {
        const self = this

        tabNavItem.addEventListener('click', function() {
            self.resetTabClasses()

            // Remove current active class
            self.elements.tabsNav.querySelector('.tabs-nav__item--active').classList.remove('tabs-nav__item--active')
            tabNavItem.classList.add('tabs-nav__item--active')

            // Set tab active
            self.elements.tabs.querySelector(tabNavItem.getAttribute('data-target')).classList.add('tab--active')
        })
    },

    resetTabClasses() {
        const self = this

        for (let i = 0; i < self.elements.tabsItems.length; i++) {
            const tabsItem = self.elements.tabsItems[i]
            tabsItem.classList.remove('tab--active')
        }
    },
}

window.addEventListener('load', function () {
    tabs.init()
})