//import Reveal from './functions/scrollReveal';
//import {scrollTo} from './functions/scrollTo';
//import CookieMessage from './components/cookieMessage'
import Accord from './components/accord'
import Sliders from './components/sliders'
import Navigation from './components/navigation';
import ImageHandling from './functions/imageHandling'
import copyClipboard from './components/copyClipboard'
import Form from './components/contactForm'
import './components/scrollToLinks'
import './components/tabs'

import Cookies from 'js-cookie'

import Sticky from 'sticky-js';
let sticky = new Sticky('.sticky');

import lottie from "lottie-web";

const logo = document.querySelector('#header .logo');

if (logo !== null) {
    const isDark = Boolean(logo.getAttribute('data-dark'));

    lottie.loadAnimation({
        container: logo, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: isDark === true ? '/lottie/logo-dark.json' : '/lottie/logo.json' // the path to the animation json
    });
}

document.querySelector('#cookie-control').addEventListener('click', (e) => {
    e.preventDefault();
    CookieControl.open()
});
