import Vue from 'vue';
Vue.config.productionTip = false;
Vue.config.devtools = false

export default new Vue({
	el: '#header',
	data: {
		isOpen: false,
		scrolled: false,
		dropCompany: false,
		dropO6k: false,
		dropLoans: false,
		dropCareers: false,
		lastScrollPosition: 0,
		hide: false,
	},
	methods: {
		toggle: function() {
			this.isOpen = !this.isOpen
			document.body.classList.toggle('fix');
		},
		toggleLink: function() {
			this.isOpen = false;
			document.body.classList.remove('fix');
			this.dropHelp = false;
			this.dropLoans = false;
		},
		companyDropdown: function(e) {
			this.dropCompany = !this.dropCompany
			if (window.innerWidth > 991) {
				window.location = 'about-us';
			}
		},
		o6kDropdown: function(e) {
			this.dropO6k = !this.dropO6k
			if (window.innerWidth > 991) {
				window.location = 'o6k';
			}
		},
		careersDropdown: function(e) {
			this.dropCareers = !this.dropCareers
			if (window.innerWidth > 991) {
				window.location = 'life-at-oakbrook';
			}
		},
		handleScroll(event) {
			let header = document.querySelector("#header");

			if (window.pageYOffset > 10 && !header.classList.contains('.header--scrolled')) {
				this.scrolled = true
			} else if (window.pageYOffset < 10) {
				this.scrolled = false
			}

			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
			if (currentScrollPosition < 0) {
				return
			}
			// Stop executing this function if the difference between
			// current scroll position and last scroll position is less than some offset
			if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 10) {
				return
			}
			this.hide = currentScrollPosition > this.lastScrollPosition
			this.lastScrollPosition = currentScrollPosition
		},

	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	}
});
